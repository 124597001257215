import React, { useState } from 'react';
import { connect } from 'react-redux';
import { navigate } from 'gatsby';
import { useStaticQuery, graphql } from 'gatsby';
//import 'antd/dist/antd.css';
import {
  Form,
  Input,
  InputNumber,
  Tooltip,
  Cascader,
  Select,
  Row,
  Col,
  Checkbox,
  /* Button, */
  AutoComplete,
} from 'antd';
import ContactFromWrapper, { SectionMainWrapper } from '../contact.style';
import Button from 'common/src/components/Button';
import PropTypes from 'prop-types';
import Box from 'common/src/components/Box';
import Text from 'common/src/components/Text';
import Heading from 'common/src/components/Heading';
import Container from 'common/src/components/UI/Container';
import { updateUserInfo } from '../../../actions';
import Cleave from 'cleave.js/react';
import PdfTermsOfService from '../../../documents/termsOfService.pdf';
import PdfPrivacyPolicy from '../../../documents/privacyPolicy.pdf';
import { createUsers } from '../../../actions';

const { Option } = Select;
const tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 0,
    },
    sm: {
      span: 16,
      offset: 2,
    },
  },
};

const ContactUs = ({
  sectionWrapper,
  row,
  contactForm,
  secTitleWrapper,
  button,
  note,
  title,
  refiReasons,
  description,
  currentUser,
  createUsers,
  updateUserInfo,
  userToken,
  showLoader,
  hideLoader,
  loading,
}) => {
  /* const data = useStaticQuery(graphql`
    query {
      cryptoJson {
        paymentPolicy {
          id
          title
          value
        }Button
        currencyOptions {
          id
          title
          value
        }
      }
    }
  `); */

  const [form] = Form.useForm();

  /* const [state, setState] = useState({
    price: '',
    currency: 'usd',
    policy: 'oneTime',
  }); */

  const [firstName, setFirstName] = useState(null);
  const [lastName, setLastName] = useState(null);
  const [mobilePhone, setMobilePhone] = useState(null);
  const [email, setEmail] = useState(null);

  /* const handleFormData = (value, name) => {
    setState({
      ...state,
      [name]: value,
    });
  }; */

  const handleChangeFirstName = (e) => {
    console.log('residence radio checked', e.target.value);
    setFirstName(e.target.value);
  };

  const handleChangeLastName = (e) => {
    console.log('property radio checked', e.target.value);
    setLastName(e.target.value);
  };

  const handleChangeMobilePhone = (e) => {
    console.log('Value', e.target.value);
    setMobilePhone(e.target.value);
  };

  const handleChangeEmail = (e) => {
    console.log('email', e.target.value);
    setEmail(e.target.value);
  };

  const onFinish = async () => {
    console.log('in on finish');
    var updateObject = {
      borrower: {
        firstName: firstName,
        lastName: lastName,
        contactPointTelephoneValue: mobilePhone,
        contactPointRoleType: 'Mobile',
      },
      miscellaneous: {
        userType: 'referralPartner',
      },
      email: email,
    };
    console.log('updateObject', updateObject);
    await createUsers(updateObject);
    navigate('/');
  };

  //   require('./reasons.module.css');//
  return (
    <SectionMainWrapper>
      <Box {...sectionWrapper}>
        <Container>
          <Box {...row}>
            <Form
              //{...formItemLayout}
              form={form}
              layout="vertical"
              name="register"
              onFinish={onFinish}
              scrollToFirstError
              style={{ width: '90%' }}
            >
              <Heading content={'Contact Us'} fontWeight="bold" {...title} />

              <Text fontWeight="bold" {...description} />

              <div className="homeValue">
                <Form.Item
                  name="firstName"
                  label="First name"
                  onChange={handleChangeFirstName}
                  /* rules={[
                        {
                          required: true,
                          message: '*Required',
                        },
                      ]} */
                >
                  <Input />
                </Form.Item>

                <Form.Item
                  name="lastName"
                  label="Last name"
                  onChange={handleChangeLastName}
                  /* rules={[
                        {
                          required: true,
                          message: '*Required',
                        },
                      ]} */
                >
                  <Input />
                </Form.Item>

                <Form.Item
                  name="mobilePhone"
                  label="Mobile phone"
                  onChange={handleChangeMobilePhone}
                  /* rules={[
                        {
                          required: true,
                          message: '*Required',
                        },
                      ]} */
                >
                  <Cleave
                    className="ant-input"
                    placeholder="888 888 8888"
                    options={{ phone: true, phoneRegionCode: 'US' }}
                  />
                </Form.Item>
              </div>

              <Form.Item
                name="email"
                label="E-mail"
                onChange={handleChangeEmail}
                rules={[
                  {
                    type: 'email',
                    message: 'Please enter a valid E-mail',
                  },
                  {
                    required: true,
                    message: 'Please input your E-mail',
                  },
                ]}
              >
                <Input />
              </Form.Item>

              <Form.Item
                name="text"
                valuePropName="checked"
                //onChange={handleAcceptedTerms}
                rules={[
                  {
                    validator: (_, value) =>
                      value ? Promise.resolve() : Promise.reject('Required'),
                  },
                ]}
              >
                <Checkbox>
                  I agree to be contacted by Best Finance via call, email and
                  text. To opt-out, you can reply “stop” at any time or click
                  the unsubscribe link in the emails. Message and data rates may
                  apply. Message frequency varies.
                </Checkbox>
              </Form.Item>

              <Form.Item
                name="agreement"
                valuePropName="checked"
                //onChange={handleAcceptedTerms}
                rules={[
                  {
                    validator: (_, value) =>
                      value ? Promise.resolve() : Promise.reject('Required'),
                  },
                ]}
              >
                <Checkbox>
                  I agree to the
                  <a href={PdfTermsOfService} target="_blank">
                    {' '}
                    <u>Terms of Use</u>
                    {''}
                  </a>
                  ,
                  <a href={PdfPrivacyPolicy} target="_blank">
                    {' '}
                    <u>Privacy Policy</u>
                  </a>
                </Checkbox>
              </Form.Item>

              <Form.Item {...tailFormItemLayout}>
                <p></p>
                <p></p>
                <div className="containerClassReasons">
                  <Button
                    type="primary"
                    title="Next"
                    htmlType="submit"
                    {...button}
                    onClick={onFinish}
                    disabled={!firstName || !lastName || !mobilePhone || !email}
                  ></Button>
                </div>
              </Form.Item>
              {/*  <li>
				<Button
				title={'Next'}
				onClick={onFinish}
				/>
</li> */}
            </Form>
          </Box>
        </Container>
      </Box>
    </SectionMainWrapper>
  );
};

ContactUs.propTypes = {
  sectionWrapper: PropTypes.object,
  secTitleWrapper: PropTypes.object,
  row: PropTypes.object,
  contactForm: PropTypes.object,
  secHeading: PropTypes.object,
  secText: PropTypes.object,
  button: PropTypes.object,
  note: PropTypes.object,
  title: PropTypes.object,
  description: PropTypes.object,
  colornote: PropTypes.object,
};

ContactUs.defaultProps = {
  sectionWrapper: {
    id: 'contact_section',
    as: 'section',
    pt: ['8px', '80px', '80px', '80px'],
    pb: ['0', '80px', '80px', '80px', '80px'],
  },
  secTitleWrapper: {
    mb: ['40px', '40px', '40px'],
    p: ['0 15px', 0, 0, 0, 0],
  },
  secText: {
    as: 'span',
    display: 'block',
    textAlign: 'center',
    fontSize: `${2}`,
    letterSpacing: '0.15em',
    fontWeight: `${6}`,
    color: 'primary',
    mb: `${3}`,
  },
  secHeading: {
    textAlign: 'center',
    fontSize: [`${6}`, `${8}`],
    fontWeight: '400',
    color: 'headingColor',
    letterSpacing: '-0.025em',
    mb: `${0}`,
  },
  row: {
    flexBox: true,
    justifyContent: 'center',
  },
  contactForm: {
    width: [1, 1, 1, 1 / 2],
  },
  button: {
    type: 'button',
    fontSize: `${20}`,
    fontWeight: '600',
    //borderRadius: '4px',
    pl: '22px',
    pr: '22px',
    colors: 'primaryWithBg',
    height: `${10}`,
  },
  note: {
    fontSize: '16px',
    fontWeight: '400',
    color: '#525f7f',
    lineHeight: '28px',
    mb: ['25px', '25px', '30px', '30px', '45px'],
    textAlign: ['center', 'center'],
  },
  colornote: {
    fontSize: '16px',
    fontWeight: '500',
    color: 'rgb(106, 82, 253)',
    lineHeight: '28px',
    mb: ['25px', '25px', '30px', '30px', '45px'],
    textAlign: ['center', 'center'],
  },
  title: {
    fontSize: ['18px', '28px', '32px', '32px', '37px'],
    fontWeight: '600',
    color: '#000000',
    letterSpacing: '-0.025em',
    mb: ['20px', '25px'],
    lineHeight: '1.31',
  },
  btnStyle: {
    minWidth: '156px',
    fontSize: '14px',
    fontWeight: '500',
  },
  label: {
    fontSize: '28px',
  },

  description: {
    content: "We'll reach out to you right away!",
    fontSize: ['10px', '12px', '18px', '18px', '20px'],
    fontWeight: '400',
    color: '#525f7f',
    lineHeight: '28px',
    mb: ['25px', '25px', '30px', '30px', '45px'],
    //textAlign: ['center', 'center'],
  },
};

const mapStateToProps = (state) => ({
  currentUser: state.root.currentUser,
});

const mapDispatchToProps = (dispatch) => {
  return {
    createUsers: (formVals) => dispatch(createUsers(formVals)),
    updateUserInfo: (currentUser, userToken) =>
      dispatch(updateUserInfo(currentUser, userToken)),
  };
};

const ContactUsRedux = connect(mapStateToProps, mapDispatchToProps)(ContactUs);

export default ContactUsRedux;
